import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import MenuIcon from "@mui/icons-material/Menu";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import { Button, createTheme, Divider, FormControl, InputLabel, MenuItem, Select, ThemeProvider, Typography } from "@mui/material";
import IntroSection from "./IntroSection";
import About from "./About/About";
import EducationAndExperience from "./EducationAndExperience";
import ContactUs from "./Contact";
import Footer from "./Footer";
import MySkills from "./MySkills";
import { MoonLogo, SunLogo } from "../Utils/Imgs";
import MyTestimonial from "./MyTestimonial";
import AdbIcon from '@mui/icons-material/Adb';
import NewTestimonial from "./NewTestimonial";
import Testimonial from "./Testimonial";

const NavBar = () => {
  const [open, setOpen] = useState(false);
  const [mode, setMode] = useState(localStorage.getItem("mode"));
  const [lang, setLang] = useState(localStorage.getItem("lang") || "en");

  const handleChange = (event) => {
    setLang(event.target.value);
    localStorage.setItem("lang", event.target.value);
  };

  useEffect(() => {
    if (!localStorage.getItem("mode")) {
      localStorage.setItem("mode", "light")
      document.documentElement.classList.add("light")
      setMode("light")
    }
    else if (localStorage.getItem("mode") === "light") {
      document.documentElement.classList.add("light")
      document.documentElement.classList.remove("dark")
      setMode("light")
    }
    else {
      document.documentElement.classList.add("dark")
      localStorage.setItem("mode", "dark")
    }
  }, [])

  const handleDarkMode = () => {
    if (localStorage.getItem("mode") === "light") {
      localStorage.setItem("mode", "dark")
      document.documentElement.classList.remove("light")
      document.documentElement.classList.add("dark")
      setMode("dark")
    }
    else {
      localStorage.setItem("mode", "light")
      document.documentElement.classList.remove("dark")
      document.documentElement.classList.add("light")
      setMode("light")
    }
  }
  const scrollToID = (ID) => {
    const section = document.getElementById(ID);
    if (section) {
      section.scrollIntoView({ behavior: "smooth" });
    }
  };
  const lightTheme = createTheme({
    palette: {
      mode: 'light',
      primary: { main: '#FA6C2C' },
      secondary: { main: '#FA6C2C' },
    },
  });

  const darkTheme = createTheme({
    palette: {
      mode: 'dark',
      primary: { main: '#FA6C2C' },
      secondary: { main: '#FA6C2C' },
    },
  });
  return (
    <div className="max-w-[1440px] mx-auto">
      <div className="fixed w-full large:w-[1440px] z-20">
        <nav className="bg-background py-[20px] iPhoneSE:py-[10px] laptop:py-[20px] flex justify-between px-[5%] items-center">
          <div className="flex items-center">
            <Link className="flex items-center" to="/">
              <AdbIcon sx={{ display: { md: 'flex' }, mr: 1, color: "#FA6C2C" }} />
              <Typography
                variant="h6"
                noWrap
                component="a"
                href="#app-bar-with-responsive-menu"
                sx={{
                  mr: 2,
                  display: { md: 'flex' },
                  fontFamily: 'monospace',
                  fontWeight: 700,
                  letterSpacing: '.2rem',
                  color: '#FA6C2C',
                  textDecoration: 'none',
                }}
              >
                Uday Kumar
              </Typography>
            </Link>

          </div>
          <ul className="flex items-center text-textColor gap-[20px] iPhoneSE:hidden laptop:flex">
            <li className="hover:text-[#FA6C2C] cursor-pointer" onClick={() => scrollToID("home")}>
              Home
            </li>
            <li className="hover:text-[#FA6C2C] cursor-pointer" onClick={() => scrollToID("about")}>
              About
            </li>
            {/* <li className="hover:text-[#FA6C2C] cursor-pointer" onClick={() => scrollToID("services")}>
                Services
              </li> */}
            <li>
              <Link to="">Resume</Link>
            </li>
            <li>
              <Link to="">Portfolio</Link>
            </li>
            <li className="hover:text-[#FA6C2C] cursor-pointer" onClick={() => scrollToID("testimonial")}>
              Testimonial
            </li>
            <li>
              <Link to="">Blog</Link>
            </li>
            <li>
              <ThemeProvider theme={mode === "dark" ? darkTheme : lightTheme}>
                <FormControl sx={{ m: 0, minWidth: 120 }} size="small">
                  <InputLabel id="demo-select-small-label">Language</InputLabel>
                  <Select
                    labelId="demo-select-small-label"
                    id="demo-select-small"
                    value={lang}
                    label="Language"
                    onChange={handleChange}
                  >
                    <MenuItem value={"te"}>Telugu</MenuItem>
                    <MenuItem value={"hi"}>Hindi</MenuItem>
                    <MenuItem value={"en"}>English</MenuItem>
                  </Select>
                </FormControl>
              </ThemeProvider>
            </li>
            <li>
              <Button
                sx={{
                  color: "white",
                  backgroundColor: "#FA6C2C",
                  ":hover": { backgroundColor: "#FA6C2C" },
                }}
                onClick={() => scrollToID("contact")}
              >
                Contact us
              </Button>
            </li>
          </ul>
          <div onClick={() => setOpen(!open)} className={`block laptop:hidden`}>
            <IconButton>{open ? <CloseIcon sx={{ color: mode === "light" ? "#000" : "#fff" }} /> : <MenuIcon sx={{ color: mode === "light" ? "#000" : "#fff" }} />}</IconButton>
          </div>
        </nav>
        <ul
          className={`dropdown-menu overflow-hidden transition-max-height duration-700 ease-in-out ${open ? 'max-h-screen' : 'max-h-0'} bg-[#0C1923] text-white text-[15px] text-start font-Poppins uppercase`}
        // className={`bg-[#0C1923] text-white text-[17px] text-start`}
        // style={{ display: open ? "block" : "none" }}
        >
          <li onClick={() => { scrollToID("home"); setOpen(false) }} className="py-[8px] pl-[5%] hover:bg-[#263139] cursor-pointer">
            Home
          </li>
          <Divider sx={{ backgroundColor: "#93989C" }} />
          <li onClick={() => { scrollToID("about"); setOpen(false) }} className="py-[8px] pl-[5%] hover:bg-[#263139] cursor-pointer">
            About
          </li>
          {/* <Divider sx={{ backgroundColor: "#93989C" }} />
          <li onClick={() => { scrollToID("services"); setOpen(false) }} className="py-[8px] pl-[5%] hover:bg-[#263139] cursor-pointer">
            Services
          </li> */}
          <Divider sx={{ backgroundColor: "#93989C" }} />
          <li className="py-[8px] pl-[5%] hover:bg-[#263139] cursor-pointer">
            <Link to="">Resume</Link>
          </li>
          <Divider sx={{ backgroundColor: "#93989C" }} />
          <li className="py-[8px] pl-[5%] hover:bg-[#263139] cursor-pointer">
            <Link to="">Portfolio</Link>
          </li>
          <Divider sx={{ backgroundColor: "#93989C" }} />
          <li onClick={() => { scrollToID("testimonial"); setOpen(false) }} className="py-[8px] pl-[5%] hover:bg-[#263139] cursor-pointer">
            Testimonial
          </li>
          <Divider sx={{ backgroundColor: "#93989C" }} />
          <li className="py-[8px] pl-[5%] hover:bg-[#263139] cursor-pointer">
            <Link to="">Blog</Link>
          </li>
          <Divider sx={{ backgroundColor: "#93989C" }} />
        </ul>
      </div>
      <div id="home" className="pt-0 laptop:pt-[50px]">
        <IntroSection lang={lang} />
      </div>
      <div className="pb-[20px]" id="about">
        <About />
      </div>
      <div className="pb-[20px]" id="about">
        <MySkills />
      </div>
      {/* <div id="services">
        <MyServices />
      </div> */}
      <div id="education">
        <EducationAndExperience />
      </div>
      {/* <div id="testimonial">
        <TestinomialContainer />
      </div> */}
      <div>
        <Testimonial/>
        {/* <MyTestimonial /> */}
        {/* <NewTestimonial/> */}
      </div>
      <div id="contact">
        <ContactUs darkMode={mode} />
      </div>
      <Footer />
      <div onClick={handleDarkMode} className="fixed bottom-[2%] right-[5%] bg-[#FA6C2C] p-[5px] rounded-full">
        {mode === "light" ?
          <img alt="sunLogo" className="h-[48px] w-[48px] cursor-pointer " src={SunLogo} /> : <img alt="moonLogo" className="h-[48px] w-[48px] cursor-pointer" src={MoonLogo} />}
      </div>

    </div>
  );
};

export default NavBar;
