export const multiLang = {
    "en": {
        introTitleOne: "I'M GLAD YOU'RE HERE.",
        introTitleTwo: "Hi 👋 I’m Uday Kumar Solving",
        animateFirst:"modern problems",
        animateTwo:"modern solutions",
        introTitleThree: "through coding",
        introDesc:"I'm a React Frontend Developer hailing from the beautiful state of Andhra Pradesh, India. Currently, I'm working as a Software Engineer at Heeddata, where I've been refining my skills and making waves in the tech world for the past 2 years."
    },
    "hi": {
        introTitleOne: "मुझे ख़ुशी है कि आप यहाँ हैं।",
        introTitleTwo: "नमस्ते 👋 मैं उदय कुमार सॉल्विंग कर रहा हूं",
        animateFirst:"आधुनिक समस्याएँ",
        animateTwo:"आधुनिक समाधान",
        introTitleThree: "कोडिंग के माध्यम से",
        introDesc:"मैं भारत के खूबसूरत राज्य आंध्र प्रदेश का रहने वाला एक रिएक्ट फ्रंटएंड डेवलपर हूं। वर्तमान में, मैं हेडडेटा में एक सॉफ्टवेयर इंजीनियर के रूप में काम कर रहा हूं, जहां मैं अपने कौशल को निखार रहा हूं और पिछले 2 वर्षों से तकनीकी दुनिया में लहरें पैदा कर रहा हूं।"
    },
    "te": {
        introTitleOne: "మీరు ఇక్కడ ఉన్నందుకు నేను సంతోషిస్తున్నాను.",
        introTitleTwo: "హాయ్ 👋 నేను ఉదయ్ కుమార్ సాల్వింగ్ చేస్తున్నాను",
        animateFirst:"ఆధునిక సమస్యలు",
        animateTwo:"ఆధునిక పరిష్కారాలు",
        introTitleThree: "కోడింగ్ ద్వారా",
        introDesc:"నేను భారతదేశంలోని అందమైన ఆంధ్రప్రదేశ్ రాష్ట్రానికి చెందిన రియాక్ట్ ఫ్రంటెండ్ డెవలపర్‌ని. ప్రస్తుతం, నేను Heeddataలో సాఫ్ట్‌వేర్ ఇంజనీర్‌గా పని చేస్తున్నాను, ఇక్కడ నేను గత 2 సంవత్సరాలుగా నా నైపుణ్యాలను మెరుగుపరుచుకుంటూ టెక్ ప్రపంచంలో అలలు సృష్టిస్తున్నాను."
    }
}