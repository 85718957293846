import './App.css';
import NavBar from './Components/NavBar';
import { SpeedInsights } from "@vercel/speed-insights/react"


function App() {
  
  return (
    <div className="bg-background">
      <NavBar/>
      <SpeedInsights />
      {/* <Outlet/> */}
    </div>
  );
}

export default App;
